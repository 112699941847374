.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    width: 100%;
    height: 100%;
    background: var(--dark-gray2);
}

.large-circle {
    width: 3rem;
    height: 3rem;
    border: 3px solid var(--gray2);
    border-radius: 50%;
    border-bottom-color: transparent;
    background: transparent;
    -webkit-animation:large-spin 2s linear infinite;
    -moz-animation:large-spin 2s linear infinite;
    animation:large-spin 2s linear infinite;
}

.small-circle {
    width: 2rem;
    height: 2rem;
    border: 3px solid var(--gray3);
    border-radius: 50%;
    border-top-color: transparent;
    -webkit-animation:small-spin 2s linear infinite;
    -moz-animation:small-spin 2s linear infinite;
    animation:small-spin 2s linear infinite;
}

@-moz-keyframes large-spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes large-spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes large-spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes small-spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes small-spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes small-spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}