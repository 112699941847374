:root {
    --blue2: #f79728;
    --black2: #333333;
    --gray2: #FBFBFD;
    --gray3: #e2e2e2;
    --dark-gray2: #252525;
}

/* Responsive */

@media only screen and (min-width: 200px) {
    html {
        font-size: 85%;
    }
    h1 {
        font-size: 1.9rem;
    }
    h3 {
        font-size: 1rem;
    }
}

@media only screen and (min-width: 768px) {
    html {
        font-size: 90%;
    }
    h1 {
        font-size: 2.1rem;
    }
    h3 {
        font-size: 1.25rem;
    }
}

@media only screen and (min-width: 992px) {
    html {
        font-size: 100%;
    }
    h1 {
        font-size: 2.5rem;
    }
    h3 {
        font-size: 1.75rem;
    }
}

/* General */

body {
    font-family: 'Poppins', sans-serif;
    background: var(--gray2);
}

h1 {
    text-transform: uppercase;
    font-weight: 800;
}

h3, h5 {
    font-weight: 700;
}

.logo {
    font-size: 1.5rem;
    color: rgba(255, 255, 255, .8);
    margin-bottom: 0;
}

.title h1 {
    position: relative;
    padding-bottom: 1rem;
}

.title h1::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    background: #ddd;
    bottom: 1px;
}

.title h1::after {
    content: '';
    position: absolute;
    display: block;
    width: 40%;
    height: 3px;
    background: var(--blue2);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.opacity-0 {
    opacity: 0;
}

/* Layout */

.height-50vh {
    min-height: 50vh;
    display: block;
}

.height-100vh {
    min-height: 100vh;
    display: block;
}

.box {
    display: block;
    height: 100%;
    background: #fff;
    border-radius: 0 0 4px 4px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
}

iframe {
    width:100%;
    height:100%;
}

/* Components */

.home, .about-page, .services-page, .contact-page {
    opacity: 0;
}