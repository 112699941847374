.footer {
    background: #121212;
    color: #fff;
}

.mapouter {
    position: relative;
    text-align: right;
    height: 200px;
    width: 100%;
}

.gmap_canvas {
    overflow: hidden;
    background: none!important;
    height: 200px;
    width: 100%;
}
