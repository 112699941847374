.service-block {
    min-height: 80vh;
}

.service-block:not(:last-child) {
    border-bottom: .5px solid #dfdfdf;
}

.service-img {
    align-self: center;
}

.service-img img {
    max-width: 100%;
    height: auto;
}

.service-text ul {
    list-style: none;
    padding: 0;
}