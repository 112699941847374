.navbar {
    background: #121212;
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
}

@media (max-width: 768px) {
    .navbar-nav {
        padding: 2rem 0;
    }
    .navbar-nav li {
        width: 50%;
        text-align: center;
        
    }
    .nav-link {
        padding: 1rem !important;
        margin: 1rem !important;
        background: #1D1D1D;
        border-radius: 5px;
        border: 1px solid #1E1E1E;
    }
    .nav-link.active {
        background: #353535;
    }
}

.navbar-brand img {
    width: 2rem;
}

.navbar-brand img {
    width: 3rem;
}

.nav-link {
    margin: 0 .5rem;
    color: #fff !important;
    text-transform: uppercase;
}

.nav-link:hover {
    color: var(--blue2) !important;
}

.nav-link.active {
    color: var(--blue2) !important;
}

@media (min-width: 768px) {
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .8rem;
        padding-left: .8rem;
    }
}

/* toggler */

.navbar-toggler {
    outline: none !important;
    color: transparent !important;
    border-color: transparent !important;
}

.toggler {
    z-index: 1001;
    cursor: pointer;
}

.toggler span {
    display: block;
    width: 30px;
    height: 2px;
    background: var(--white);
    border-radius: 5px;
}

.toggler span:first-child, .toggler span:first-child+span {
    margin-bottom: 7px;
}