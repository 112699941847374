.intro {
    position: relative;
    height: 100vh;
    background: #000;
}

@media (max-width: 768px) {
    .intro {
        background-image: none !important;
    }
}

.intro-background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
}

@media (max-width: 768px) {
    .intro-background {
        z-index: -1;
    }
}

.intro-background {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas: "intro1 intro2 intro3 intro3" "intro1 intro4 into5 intro6" "intro7 intro7 intro8 intro6";
}

.intro-background {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas: "intro1 intro5 intro4" "intro1 intro2 intro4" "intro1 intro3 intro3";
}

.intro1 {
    grid-area: intro1;
}

.intro2 {
    grid-area: intro2;
}

.intro3 {
    grid-area: intro3;
}

.intro4 {
    grid-area: intro4;
}

.intro5 {
    grid-area: intro5;
}

.intro-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.intro-content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    padding: 2rem;
    background: rgba(0, 0, 0, .9);
}

.intro-content h1 {
    color: #fff;
}

.intro-content h3 {
    font-size: 1.5rem;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.856);
}


/* Intro Text */

.it-h, .it-p {
    max-width: 900px;
    margin: 0 auto;
    display: none;
    opacity: 0;
}