.about-block {
    min-height: 80vh;
}

.about-block:not(:last-child) {
    border-bottom: .5px solid #dfdfdf;
}

.about-img {
    align-self: center;
}

.about-img img {
    max-width: 100%;
    height: auto;
}

.about-text ul {
    list-style: none;
    padding: 0;
}

.about-quote {
    border-left: .5rem dashed var(--blue2);
    padding-left: 2rem;
}

@media (max-width: 768px) {
    .about-quote {
        margin-left: 1rem;
    }
}