.partner {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 0 0 4px 4px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
}

.partner img {
    width: 8rem; /* You can set the dimensions to whatever you want */
    max-height: 8rem;
}